@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: GeistSans;
  font-weight: 300;
  src: url("./assets/font/Geist-Light.otf") format("opentype");
}

@font-face {
  font-family: GeistSans;
  src: url("./assets/font/Geist-Regular.otf") format("opentype");
}

@font-face {
  font-family: GeistSans;
  font-weight: 500;
  src: url("./assets/font/Geist-Medium.otf") format("opentype");
}

@font-face {
  font-family: GeistSans;
  font-weight: 600;
  src: url("./assets/font/Geist-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: GeistSans;
  font-weight: 700;
  src: url("./assets/font/Geist-Bold.otf") format("opentype");
}

@font-face {
  font-family: GeistSans;
  font-weight: 800;
  src: url("./assets/font/Geist-Black.otf") format("opentype");
}



@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 72.22% 50.59%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5% 64.9%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --radius: 0.5rem;
  }
}

@layer utilities {
  .popover-trigger-width {
    width: var(--radix-popover-trigger-width);
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;


    font-feature-settings: "rlig" 1, "calt" 1;

  }
}

/*html {*/
/*  font-size: 14px;*/
/*}*/

body {
  margin: 0;
  color: hsl(var(--foreground));
  font-family: GeistSans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.label {
  color: var(--white);
  font-size: 12px;
}

.page-container {
  padding: 15px;
}

/**  ::-webkit-scrollbar { */

/*  width: 6px; */

/*  height: 6px; */

/* } */

/**  ::-webkit-scrollbar-track { */

/*  background-color: var(--background); */

/* } */

/**  ::-webkit-scrollbar-thumb { */

/*  background-color: var(--scrollbar-thumb-color); */

/* } */

* {
  /* color: var(--white); */
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
ul {
  /* color: var(--white); */
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  /* color: black; */
  text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

body {
  /* --background: #f3f5f9; */

  /* --blue: #0d80d8; */

  /* --whiteBackground: white; */

  /* --whiteBackground2: white; */

  /* --white: black; */

  /* --box-shadow: rgb(40 40 40 / 15%); */

  /* --select-box-shadow-color: rgb(40 40 40 / 21%); */

  /* --hover: #f2f2f2; */

  /* --current: #eaeaea; */

  /* --select-border-color: rgb(0 0 0 / 23%); */

  /* --border-color-dark: transparent; */

  /* --border-color: rgb(224 224 224); */

  /* --scrollbar-track-color: lightgray; */

  /* --scrollbar-thumb-color: #b7b7b7; */

  /* --red-light: #ffb2b2; */

  /* --red: #f44336; */

  /* --green: #4caf50; */

  /* toner colors */
  --toner-white: white;
  --toner-purple: #d500f9;
  --toner-blue: #35baf6;
  --toner-yellow: #ffeb3b;
  --toner-black: rgb(0 0 0 / 61%);
  --drawer-width: 400px;
}

:root[data-theme="dark"] body {
  /* --background: #1c2128; */

  /* --white: #adbac7; */

  /* --hover: #323941; */

  /* --current: #39404b; */

  /* --border-color: #373e47; */

  /* --box-shadow: rgb(0 0 0 / 0%); */

  /* --select-box-shadow-color: rgb(0 0 0 / 20%); */

  /* --border-color-dark: #373e47; */

  /* --select-border-color: #373e47; */

  /* --whiteBackground2: #22272e; */

  /* --scrollbar-track-color: #2b313a; */

  /* --scrollbar-thumb-color: #3a424f; */

  /* --whiteBackground: #22272e; */

  /* --red-light: #5b201c; */

  /* --red: #aa2e25; */

  /* --green: #357a38; */

  /* toner colors */
  --toner-white: #adbac7;
  --toner-purple: #9c27b0;
  --toner-blue: #2196f3;
  --toner-yellow: #ccbd38;
  --toner-black: rgb(0 0 0 / 38%)
}

@media screen and (max-width: 568px) {
  :root {
    --drawer-width: 300px
  }
}
