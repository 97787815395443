.toggle {
  position: relative;
  display: flex;
  width: 57px;
  height: 25px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  cursor: pointer;
}

.ball {
  position: absolute;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--white);
}

.ball.active {
  right: 4px;
  left: auto;
}

.checkbox {
  display: none;
}

.toggle svg {
  z-index: 1;
  width: 30px;
  color: var(--white);
}

.sun.active {
  color: white;
}

.moon.active {
  color: black;
}