.popup {
  display: flex;
  align-items: center;
}

.closeBlock {
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 5px;
  border: 1px solid red;
  cursor: pointer;
  font-size: 0.9rem;
  gap: 5px;
}

.content {
  position: absolute;
  z-index: 2;
  top: -200px;
  left: 0;
  width: 100vw;
  padding: 10px 15px;
  border-bottom: 1px solid var(--border-color-dark);
  background: var(--whiteBackground);
  box-shadow: 0 1px 3px var(--box-shadow);
  transition: 0.15s ease-in-out;
}

.content.active {
  top: 0;
  transition: 0.15s ease-in-out;
}

@media screen and (max-width: 768px) {
  .popup {
    z-index: 50;
  }
}
