.icon {
    display: block;
    padding: 3px;
    border-radius: 20px;
    color: var(--white);
    cursor: pointer;
}

.icon:hover {
    background: rgb(211 211 211 / 39%);
}

.icon.open {
    font-size: 25px;
}

.icon.reset {
    font-size: 25px;
}