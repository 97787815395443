.printer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  width: 100%;
}

.sectionTitle {
  display: inline-block;
  padding: 5px;
  margin-bottom: 5px;
}

.back {
  display: inline-flex;
  width: fit-content;
  align-items: center;
  padding: 5px 8px;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  color: var(--white);
  font-size: 12px;
}

.ranges {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 15px 0 15px 5px;
  gap: 5px;
}

.chartBlock {
  position: relative;
}

.findIcon {
  color: var(--white);
}

.empty {
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--white);
  font-size: 20px;
  opacity: 0.3;
  transform: translate(-50%, -50%);
}
