.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    width: 170px;
    height: 170px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    border-radius: 50%;
    gap: 5px;
    transform: translate(-50%, -50%);
}