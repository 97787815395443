.toners {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
}

.tonerBlock {
    display: flex;
    align-items: center;
}

.tonerTitle {
    width: 80px;
    color: var(--white);
    font-size: 12px;
}

