.background {
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background: rgb(0 0 0 / 20%);
  opacity: 0;
}

.background.active {
  z-index: 5;
  backdrop-filter: blur(1px);
  opacity: 1;
  transition: opacity 0.15s ease-in-out;
}
