.views {
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;
  color: var(--white);
}

.viewBlock {
  position: relative;
  display: flex;
}

.view {
  color: gray;
  cursor: pointer;
  font-size: 20px;
  transition: 0.15s ease-in-out;
}

.view:hover {
  color: var(--white);
  transition: 0.15s ease-in-out;
}

.view.active {
  color: var(--blue);
}
