.editParam {
    display: flex;
    flex-direction: column;
    color: var(--white);
    gap: 15px;
}

.title {
    margin-bottom: 15px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.input {
    padding: 10px;
    border: 1px solid var(--border-color);
    background: transparent;
    color: var(--white);
}

.addValue {
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color)
}

.addValue .input {
    width: calc(100% - 35px);
    height: 100%;
    border: none;
    outline: none;
}

.addIcon {
    width: 35px;
    height: 100%;
    padding: 3px;
    background: var(--blue);
    color: white;
    cursor: pointer;
    transition: 0.1s ease-in-out;
}

.addIcon.disabled {
    opacity: 0.6;
    pointer-events: none;
    transition: 0.1s ease-in-out;
}

.errorText {
    color: var(--red);
}

.values {
    display: flex;
    max-width: var(--drawer-width);
    flex-wrap: wrap;
    gap: 15px;
}

.valueBlock {
    display: flex;
    width: fit-content;
    align-items: center;
}

.valueContent {
    padding: 10px;
    border: 1px solid var(--border-color);
    border-right: none;
}

.remove {
    width: 30px;
    height: 100%;
    padding: 7px;
    border: 1px solid var(--border-color);
    color: var(--red);
    cursor: pointer;
    transition: 0.1s ease-in-out;
}

.remove:hover {
    border-color: var(--red);
    transition: 0.1s ease-in-out;
}
