.error {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);
}

.icon {
    width: 150px;
    height: 150px;
    color: var(--red);
}
